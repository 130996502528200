<template>
  <div class="app-container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部停报客户" name="ninth">
        <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'ninth'" :listQuery="listQuery"></contentTable>
      </el-tab-pane>
    </el-tabs> -->
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部停报客户" name="ninth">
        <template v-if="tabName == 'ninth'">
          <div style="margin-bottom:10px">
            <el-input size="small" placeholder="公司名称" v-model="listQuery.name" style="width:150px" @keyup.enter="getList"></el-input>
            <el-button size="small" type="primary" @click="getList" style="margin-right:10px" >
              <el-icon><Search /></el-icon> <span  >搜索</span>
            </el-button>
          </div>
          <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'ninth'" :listQuery="listQuery"></contentTable>
        </template>
      </el-tab-pane>
      <el-tab-pane label="回收站" name="fifth">
        <template v-if="tabName == 'fifth'">
          <contentTable ref="clientList" :tabName="tabName" :listQuery="listQuery"></contentTable>
        </template>
      </el-tab-pane>
    </el-tabs>
    
    <!-- <div class="bottom_style">
      <div class="bottom_button">
        <el-button size="small" type="primary" plain >还原到未分配客户</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import contentTable from "./components/contentTable.vue";
export default {
  name: "stopReporting",
  components:{
    contentTable,
  },
  data() {
    return {
      activeName: "ninth",
      tabName:'ninth',
      listQuery:{
        status:'3',
        page:1,
        limie:20,
        userIds:[this.$store.getters['user/user'].userId]
      }
    }
  },
  created(){
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.off("stopReportingUpdate")
      this.$bus.on("stopReportingUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.$refs.clientList.getList()
    },
    handleClick(tab, event) {
      this.listQuery.status = "3"
      if (tab.props.label == "全部停报客户") {
        this.tabName = tab.props.name
      }else if (tab.props.label == "回收站") {
        this.tabName = tab.props.name
        this.listQuery.status = "9"
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .left_box{
    float: left;
    width: 70%;
  }
</style>